import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Popover,
  Radio,
  Row,
  Space,
  message
} from 'antd'
import _ from 'lodash'
import React, { FC, useMemo } from 'react'
import { DrawerScoreTextSignMode } from 'typing.marking'
import CustomScoreTrigger from '../custom-score/Trigger'
import {
  ScoreSign,
  ScoreTextPrefix,
  useDrawerContent
} from '../useDrawerContent'

export const ScoreToolbar: FC<{
  maxScore?: number
}> = props => {
  const {
    resetToolMode,

    score,
    setScore,

    scoreSignMode,
    setScoreSignMode,
    scoreSignReadonly,

    settingScoreTextSignMode,

    customMarkerScore
  } = useDrawerContent()

  const list = useMemo(() => {
    return customMarkerScore
  }, [customMarkerScore])

  function onSignChange(val: ScoreSign) {
    if (settingScoreTextSignMode === DrawerScoreTextSignMode.ALL) {
      if (scoreSignReadonly) {
        message.warn(
          `当前是${
            scoreSignMode === 'plus' ? '加分' : '减分'
          }模式, 请删除打分标注后再切换模式`
        )
        return
      }
      setScoreSignMode(val)
    } else {
      message.warn('当前阅卷不支持切换加减分模式')
    }
  }

  function onInput(val: number) {
    setScore(_.isNumber(val) ? val : list[0].value)
  }

  return (
    <div className='score-toolbar'>
      <div className='main'>
        <Row align='middle' gutter={16} style={{ flexWrap: 'nowrap' }}>
          <Col style={{ flex: '0 0 auto' }}>
            <Radio.Group
              value={scoreSignMode}
              onChange={e => onSignChange(e.target.value)}
              buttonStyle={'solid'}
              size='large'
            >
              <Radio.Button value={'plus'}>加分</Radio.Button>
              <Radio.Button value={'minus'}>减分</Radio.Button>
            </Radio.Group>
          </Col>
          <Col style={{ flex: '0 0 auto' }}>
            <InputNumber
              min={0}
              // max={props.maxScore}
              onChange={val => onInput(val as number)}
              placeholder='自定义分数'
              size='large'
              style={{
                width: 130
              }}
            />
          </Col>
          <Col flex={1}>
            <Space>
              {list.slice(0, 7).map((it, i) => (
                <Button
                  key={i}
                  tabIndex={-1}
                  type={it.value === score ? 'primary' : 'default'}
                  onClick={() => onInput(it.value)}
                >
                  {it.value}
                </Button>
              ))}
            </Space>
          </Col>
          <Col style={{ flex: '0 0 auto' }}>
            <div className='color-width'>
              <Popover content={<ScoreMarkerSetting />}>
                <div className='field'>
                  <span>更多</span>
                  <img
                    src={require('../../../assets/icon-arrow.png')}
                    style={{
                      transform: 'rotate(90deg)'
                    }}
                    alt=''
                  />
                </div>
              </Popover>
            </div>
          </Col>
        </Row>
      </div>
      <div className='spLine'></div>
      <ul className='menu right'>
        <li className='primary' onClick={resetToolMode}>
          <img src={require('../images/cancle.png')} alt='' />
        </li>
      </ul>
    </div>
  )
}

const ScoreMarkerSetting: FC = () => {
  const {
    fontSize,
    setFontSize,
    scoreMarkerPrefix,
    setScoreMarkerPrefix,
    isScoreMarkerBracketWrapped,
    toggleScoreMarkerBracketWrapped
  } = useDrawerContent()

  return (
    <Form>
      <Form.Item label='字号' style={{ marginBottom: 8 }}>
        <InputNumber
          value={fontSize}
          onChange={(v?: number | string) =>
            typeof v === 'number' && setFontSize(v)
          }
        />
      </Form.Item>
      <Form.Item label='显示横线' style={{ marginBottom: 8 }}>
        <Checkbox
          checked={!!scoreMarkerPrefix}
          onChange={e =>
            setScoreMarkerPrefix(e.target.checked ? ScoreTextPrefix : '')
          }
        />
      </Form.Item>
      <Form.Item label='显示括号' style={{ marginBottom: 8 }}>
        <Checkbox
          checked={isScoreMarkerBracketWrapped}
          onChange={e => toggleScoreMarkerBracketWrapped(e.target.checked)}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <CustomScoreTrigger />
      </Form.Item>
    </Form>
  )
}
